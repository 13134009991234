import { notify } from '../utils/notify'
import { generatePathAsset } from '../utils'

const madeMarker = (data) => {
  const item = document.createElement('lightship-map-point')
  item.setAttribute('id', data.swarm_id)
  item.setAttribute('lat-lng', `${data.lat} ${data.lng}`)
  item.setAttribute('position', { x: 0, y: 25, z: 0 })
  item.setAttribute('scale', '2 2 2')
  item.setAttribute('gltf-model', `url(${generatePathAsset(data.point_3d)})`)
  item.setAttribute('class', 'cantap')
  item.setAttribute(
    'animation',
    'property: rotation; to: 0 360 0; loop: true; dur: 5000; easing: linear;',
  )
  //passed swarm_id to the entity
  item.setAttribute('swarm_id', data.swarm_id)
  item.setAttribute('challenge_id', data.challenge_id)

  return item
}

const madeEntityTarget = (data) => {
  const el = document.createElement('a-entity')
  el.setAttribute('id', 'entity-target')
  el.setAttribute('position', { x: 0, y: 1, z: -5 })
  el.setAttribute('scale', '0.5 0.5 0.5')
  el.setAttribute('gltf-model', `url(${generatePathAsset(data.target_3d)})`)
  el.setAttribute('class', 'cantap')
  el.setAttribute(
    'animation',
    'property: rotation; to: 0 360 0; loop: true; dur: 5000; easing: linear;',
  )
  return el
}
const WS_URL = `wss://${process.env.REACT_APP_SOCKET_URL}`
const haversineDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371e3 // metres
  const φ1 = (lat1 * Math.PI) / 180 // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180
  const Δφ = ((lat2 - lat1) * Math.PI) / 180
  const Δλ = ((lon2 - lon1) * Math.PI) / 180

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  const d = R * c // in metres
  return d
}
const getDataComponents = (navigate, fetch_data = undefined) => {
  return {
    async init() {
      this.lightshipMap = document.querySelector('lightship-map')
      this.nearestPoint = async (data = [], threshold = 10) => {
        const currentLatLng = await this.getLatLng()
        let nearest = null
        let distance = 0
        data.forEach((el) => {
          const d = haversineDistance(
            currentLatLng.lat,
            currentLatLng.lng,
            el.lat,
            el.lng,
          )
          if (d <= threshold) {
            if (nearest === null) {
              nearest = el
              distance = d
            } else {
              if (d < distance) {
                nearest = el
                distance = d
              }
            }
          }
        })
        return nearest
      }
      this.getLatLng = () => {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve({
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              })
            },
            (error) => {
              reject(error)
            },
          )
        })
      }
      const fragment = document.createDocumentFragment()
      try {
        const latLng = await this.getLatLng()
        const map_data = await fetch_data(latLng)
        this.requestInProgress = false
        const len = map_data.length
        let i = 0
        const addMarker = () => {
          if (i < len) {
            const sw = map_data[i]
            const target = madeMarker(sw)
            fragment.appendChild(target)
            i++
            requestAnimationFrame(addMarker)
          } else {
            this.lightshipMap.appendChild(fragment)
          }
        }
        requestAnimationFrame(addMarker)
      } catch (e) {
        console.log('e', e)
      } finally {
        const socket = new WebSocket(WS_URL)
        //unset all socket
        socket.onclose = () => {
          console.log('socket close')
        }
        socket.onopen = () => {
          console.log('socket open')
        }
        socket.onmessage = (e) => {
          const objectData = JSON.parse(e.data)
          if (objectData.type === 'captured') {
            const { data } = objectData
            const { swarm_id } = data
            const targetParent = document.querySelector('lightship-map')
            if (targetParent) {
              const target = targetParent.querySelector(`#${swarm_id}`)
              if (target) {
                this.lightshipMap.removeChild(target)
              }
            }
          }
        }
      }
    },
    async tock() {
      //get lightship-map-point with id
      try {
        if (this.requestInProgress) {
          return
        }
        const data = Array.from(
          this.lightshipMap.querySelectorAll('lightship-map-point'),
        ).map((el) => {
          const latLng = el.getAttribute('lat-lng').split(' ')
          return {
            swarm_id: el.getAttribute('swarm_id'),
            challenge_id: el.getAttribute('challenge_id'),
            lat: parseFloat(latLng[0]),
            lng: parseFloat(latLng[1]),
          }
        })
        const nearest = await this.nearestPoint(data, 30)
        if (nearest) {
          this.requestInProgress = true
          notify('Collect Items', true, 'Start AR', false, true)
            .then((r) => {
              if (r.isConfirmed) {
                return (window.location.href = `/scene?challenge_id=${nearest.challenge_id}&swarm_id=${nearest.swarm_id}`)
              }
            })
            .finally(() => {
              //give 5 seconds
              setTimeout(() => {
                this.requestInProgress = false
              }, 5000)
            })
        }
      } catch (e) {
        console.log('err', e)
      }
    },
  }
}
const getTargetComponents = (navigate, api, user) => {
  return {
    async init() {
      const qs = new URLSearchParams(window.location.search)
      const id = qs.get('challenge_id')
      const active_user = user.getActive()
      if (!id || !active_user) {
        navigate('/map')
      }
      console.log('initizlie')
      const _data = await api.getChallengeDo(id)
      const target = madeEntityTarget(_data)
      const el = this.el
      el.appendChild(target)
      el.addEventListener('click', () => {
        target.setAttribute('visible', false)
        const _qs = new URLSearchParams(window.location.search)
        const swarm_id = _qs.get('swarm_id')
        const payload = {
          captured_at: new Date().toISOString(),
          user_id: active_user.id,
          challange_id: id,
          swarm_id: swarm_id,
        }
        api
          .captureDo(payload)
          .then(() => {
            navigate('/reward', { state: _data })
          })
          .catch((e) => {
            notify(
              'Congratulations you have an item, scratch the coupon on the Wallet!',
              true,
              'go to Wallet',
              false,
            ).then((r) => {
              if (r.isConfirmed) {
                window.location.href = '/wallet'
              }
            })
          })
      })
    },
  }
}

export { getDataComponents, getTargetComponents }
