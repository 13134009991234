import axios from 'axios'
import { getStorage } from '../../../utils/getStorage'
import Cookies from 'js-cookie'

export const API_URL = process.env.REACT_APP_BASE_URL
const injectCookie = (data) => {
  const time = new Date().getTime()
  Cookies.set('access_token', data.access_token, {
    expires: new Date(time + data.expires),
  })
  Cookies.set('refresh_token', data.refresh_token)
}

export const http = (respType, baseUrl, withCredential = true) => {
  const _ax = axios.create({
    baseURL: baseUrl ?? API_URL,
    timeout: respType === 'blob' ? 99999 : 15000,
    responseType: respType ?? 'json',
    withCredentials: withCredential,
  })
  _ax.interceptors.request.use(
    (config) => {
      if (
        getStorage('access_token') &&
        getStorage('access_token') !== 'undefined'
      ) {
        config.headers.Authorization = 'Bearer ' + getStorage('access_token')
      }
      config.headers.Accept = 'application/json'
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
  //implement refresh token
  _ax.interceptors.response.use(
    async (response) => {
      return response
    },
    async (error) => {
      const currentPath = window.location.pathname
      if (currentPath !== '/login') {
        const originalRequest = error.config
        if (
          (error.response.status === 401 || error.response.status === 403) &&
          !originalRequest._retry
        ) {
          originalRequest._retry = true
          const refresh_token = getStorage('refresh_token')
          if (!refresh_token || refresh_token === 'undefined') {
            window.location.href = '/login'
          }
          return _ax
            .post(`${API_URL}/auth/refresh`, {
              refresh_token: getStorage('refresh_token'),
              mode: 'json',
            })
            .then((res) => {
              if (res.status === 200) {
                const data = res.data
                injectCookie(data?.data)
                originalRequest.headers.Authorization =
                  'Bearer ' + data.access_token
                return _ax(originalRequest)
              }
            })
        }
        //after refresh token still error
        if (error.response.status === 401 || error.response.status === 403) {
          window.location.href = '/login'
        }
      }

      return Promise.reject(error)
    },
  )

  return _ax
}
