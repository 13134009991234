import React, {useEffect, useState} from 'react';
import Wrapper from "../wrapper";
import Logo from '../../assets/svgs/logo.svg'
import {AnimatePresence, motion} from 'framer-motion';
import {useNavigate} from "react-router-dom";

const Index = () => {
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setIsVisible(false)
        }, 2000)
        if (!isVisible) {
            setTimeout(() => {
                navigate('/login')
            }, 300)
        }
    }, [isVisible]);
    return (
        <Wrapper>
            <AnimatePresence initial={true}>
                {isVisible && (
                    <motion.div
                        initial={{scale: 0, rotate: 180}}
                        animate={{rotate: 0, scale: 1}}
                        exit={{scale: 0, rotate: 180}}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <img src={Logo} alt="logo-katchit"/>
                    </motion.div>
                )}
            </AnimatePresence>


        </Wrapper>
    );
};

export default Index;
