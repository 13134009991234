import { useState } from 'react'

const useLoading = () => {
  const [loading, setLoading] = useState(false)
  const on = () => {
    setLoading(true)
  }
  const off = () => {
    setTimeout(() => setLoading(false), 500)
  }
  return { loading, on, off }
}

export default useLoading
