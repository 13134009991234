import React from 'react'
import topBg from '../../assets/svgs/top-bg-1.svg'

const Index = () => {
  return (
    <div className="headerbg fixed top-0 max-w-screen-sm  w-[100dvw]">
      <img className="w-[100dvw] m-auto" src={topBg ?? ''} alt="" />
    </div>
  )
}

export default Index
