import { API_URL } from '../service/api/config'

const generatePathAsset = (_path) => `${API_URL}/assets/${_path};`
const hexToArrayBuffer = (hex) => {
  const bytes = new Uint8Array(hex?.length / 2)
  for (let i = 0; i < hex?.length; i += 2) {
    bytes[i / 2] = parseInt(hex.substr(i, 2, 2), 16)
  }
  return bytes.buffer
}

// Function to convert ArrayBuffer to string
const arrayBufferToString = (buffer) => {
  const byteArray = new Uint8Array(buffer)
  return String.fromCharCode.apply(null, byteArray)
}
const decryptMessage = async (data) => {
  const encryptedMessage = data?.enc
  if (!encryptedMessage) {
    return data
  }
  const keyHex = process.env.REACT_APP_SECRET_KEY
  const [ivHex, encryptedHex] = encryptedMessage.split(':')
  const iv = hexToArrayBuffer(ivHex)
  const encryptedArrayBuffer = hexToArrayBuffer(encryptedHex)

  const keyMaterial = await window.crypto.subtle.importKey(
    'raw',
    hexToArrayBuffer(keyHex),
    { name: 'AES-CBC' },
    false,
    ['decrypt'],
  )

  const decryptedArrayBuffer = await window.crypto.subtle.decrypt(
    { name: 'AES-CBC', iv: iv },
    keyMaterial,
    encryptedArrayBuffer,
  )

  return JSON.parse(arrayBufferToString(decryptedArrayBuffer))
}
export { generatePathAsset, decryptMessage }
