import React from 'react'

const Index = ({ title }) => {
  return (
    <div className="title text-center mt-10 text-[24px] font-semibold text-white">
      {title}
    </div>
  )
}

export default Index
