import useLoading from '../config/useLoading.api'
import { useEffect } from 'react'
import {
  login,
  refresh,
  requestPassword,
  resetPassword,
  signup,
} from './login.api'
import { loadingIndicator, notify } from '../../../utils/notify'
import Cookies from 'js-cookie'
import { API_URL } from '../config'
import { useNavigate } from 'react-router-dom'
import useUsersApi from '../user'

const useLoginApi = () => {
  const users = useUsersApi()
  const isAuth =
    window.location.pathname === '/login' &&
    window.location.search?.includes('?auth=')
  const detectTokenCookieExists = () => {
    if (
      Cookies.get('access_token') &&
      Cookies.get('access_token') !== 'undefined'
    ) {
      Cookies.get('refresh_token')
      return true
    } else {
      return false
    }
  }
  const getAuthToken = () => {
    if (isAuth) {
      return window.location.search.split('?auth=')[1]
    }
    return null
  }
  const { loading, on, off } = useLoading()
  const navigate = useNavigate()
  const injectCookie = (data) => {
    const time = new Date().getTime()
    Cookies.set('access_token', data.access_token, {
      expires: new Date(time + data.expires),
    })
    Cookies.set('refresh_token', data.refresh_token)
    users.getMeDo()
  }
  const googleRedirectLink = () => {
    const hostWithProtocol = window.location.origin
    const redirectLink = `${hostWithProtocol}/login?auth=`
    return (
      API_URL +
      `/auth/login/google?redirect=${API_URL + `/sso/redirect-with-token?redirect=${redirectLink}`}`
    )
  }
  const onRefresh = async (redir = true) => {
    on()
    await refresh({
      refresh_token: getAuthToken() ?? Cookies.get('refresh_token'),
    })
      .then((r) => {
        const data = r.data
        injectCookie(data)
        if (redir) {
          navigate('/main')
        }
      })
      .catch((e) => {
        notify(e.response.data.errors[0].message)
      })
      .finally(() => off())
  }
  const onLogin = async (body) => {
    Cookies.remove('access_token')
    Cookies.remove('refresh_token')

    on()
    await login(body)
      .then((r) => {
        const data = r.data
        injectCookie(data)
        navigate('/main')
      })
      .catch((e) => {
        notify(e.response.data.errors[0].message)
      })
      .finally(() => off())
  }
  const onSignup = async (body) => {
    on()
    try {
      await signup(body)
      await notify('Signup Successfully').then(() => navigate('/login'))
    } catch (e) {
      await notify(e.response.data.errors[0].message)
    } finally {
      off()
    }
  }
  const requestResetDo = async (email = undefined) => {
    if (!email) {
      return
    }
    on()
    try {
      await requestPassword({ email })
      notify('Reset link has been sent to your email')
    } catch (e) {
      notify(e.response.data.errors[0].message)
    } finally {
      off()
    }
  }
  const resetPasswordDo = async (body) => {
    on()
    try {
      await resetPassword(body)
      notify('Password has been reset')
      navigate('/login')
    } catch (e) {
      notify(e.response.data.errors[0].message)
    } finally {
      off()
    }
  }
  useEffect(() => {
    if (loading) {
      loadingIndicator()
    }
  }, [loading])
  useEffect(() => {
    if (isAuth) {
      onRefresh()
    }
  }, [isAuth])

  useEffect(() => {
    if (detectTokenCookieExists()) {
      navigate('/main')
      // window.location.href = '/map'
    }
  }, [])
  return {
    onLogin,
    googleRedirectLink,
    requestResetDo,
    resetPasswordDo,
    onSignup,
  }
}
export default useLoginApi
