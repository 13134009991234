import React from 'react'
import { AFrameScene } from '../lib/aframe-components'
import html from './map.html'
import { collectComponents } from '../lib/collect'
import NavButton from '../components/navButton'
import { FaWallet } from 'react-icons/fa'
import { HiUser } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import useChallengesApi from '../service/api/challenges/challenges.api'
import { getDataComponents } from '../lib/get-data'
import { mapLoadingScreenComponent } from '../lib/map-loading'

const Map = () => {
  const navigate = useNavigate()
  const challengesApi = useChallengesApi()

  return (
    <>
      <AFrameScene
        sceneHtml={html}
        components={[
          { name: 'ar-scene', val: collectComponents(navigate) },
          { name: 'map-loading-screen', val: mapLoadingScreenComponent },
          {
            name: 'get-data',
            val: getDataComponents(navigate, challengesApi.onGetMapData),
          },
        ]}
      />
      <div className="z-[99] absolute left-[2vw] top-[2vh]">
        <NavButton icon={<FaWallet />} to={'/wallet'} />
      </div>
      <div className="z-[99] absolute left-[2vw] bottom-[2vh]">
        <NavButton icon={<HiUser />} to={'/profile'} />
      </div>
    </>
  )
}

export { Map }
