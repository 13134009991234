import { http } from '../config'

const PREFIX = ''

const login = async (body) => {
  try {
    let res = await http().post(`${PREFIX}/auth/login`, body)
    return res.data
  } catch (err) {
    throw err
  }
}

const signup = async (body) => {
  try {
    let res = await http().post(`${PREFIX}/users`, body)
    return res.data
  } catch (err) {
    throw err
  }
}

//auth/refresh

const refresh = async (body) => {
  try {
    let res = await http().post(`${PREFIX}/auth/refresh`, body)
    return res.data
  } catch (err) {
    throw err
  }
}
//auth/password/reset

const resetPassword = async (body) => {
  try {
    let res = await http().post(`${PREFIX}/auth/password/reset`, body)
    return res.data
  } catch (err) {
    throw err
  }
}
//auth/password/request

const requestPassword = async (body) => {
  try {
    const _origin = window.location.origin
    const _redirect = `${_origin}/confirmation`
    let res = await http().post(`${PREFIX}/auth/password/request`, {
      ...body,
      reset_url: _redirect,
    })
    return res.data
  } catch (err) {
    throw err
  }
}
export { login, refresh, resetPassword, requestPassword, signup }
