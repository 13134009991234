import React from 'react'

const Index = ({ children, position = 'middle', fullwidth = false }) => {
  const positionMap = {
    start: 'items-start',
    middle: 'items-center',
    end: 'items-end',
  }
  return (
    <div
      className={`bg-body flex-wrap max-w-screen-sm m-auto h-[100dvh] flex overflow-hidden ${positionMap[position]} justify-center ${!fullwidth && 'p-5'}`}
    >
      {children}
    </div>
  )
}

export default Index
