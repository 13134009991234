import React, { useEffect } from 'react'
import topBg from '../../assets/svgs/top-bg-1.svg'
import bottomBg from '../../assets/svgs/whitebg.svg'
import Wrapper from '../../components/wrapper'
import Logo from '../../assets/svgs/logo.svg'
import Title from '../../components/title'
import { motion } from 'framer-motion'
import coin from '../../assets/pngs/coin.png'
import wallet from '../../assets/pngs/wallet.png'
import Button from '../../components/button'
import useUsersApi from '../../service/api/user'
import { useNavigate } from 'react-router-dom'

const Index = () => {
  const user_v1 = useUsersApi()
  const [tokenAmount] = React.useState(0)
  const navigate = useNavigate()
  useEffect(() => {
    user_v1.getMeDo()
  }, [])
  return (
    <Wrapper fullwidth position="start">
      <div className="headerbg fixed top-0 max-w-screen-sm  w-[100dvw]">
        <img className="w-[100dvw] m-auto" src={topBg ?? ''} alt="" />
      </div>
      <div className="footerbg fixed bottom-0 max-w-screen-sm  w-[100dvw]">
        <img className="w-[100dvw] h-[100dvh] m-auto" src={bottomBg ?? ''} alt="" />
      </div>
      <motion.div
        initial={{ position: 'absolute', bottom: '-20rem' }}
        animate={{ position: 'relative', bottom: 'unset' }}
        transition={{
          type: 'ease',
          stiffness: 260,
          damping: 20,
        }}
      >
        <div className="content z-50">
          <div className="w-full">
            <Title title={'Profile'} />
          </div>
          <div className="content mt-3">
            <img src={Logo} style={{width:98, height:98}} className={'m-auto mb-2'} alt="logo-katchit" />
            <div className="profile-name text-[28px] text-center font-bold">
              {user_v1.user?.first_name ?? ''} {user_v1.user?.last_name ?? ''}
            </div>
            <div className="username text-[18px] text-[#FD5014] text-center font-bold mb-5">
              {user_v1.user?.email ?? ''}
            </div>
            <div className="shadow-field-outset mb-3 rounded-[30px] w-full min-w-[327px]">
              <div className="shadow-field-inset rounded-[30px] w-full min-w-[327px]">
                <div className="flex p-3 font-bold px-5 items-center gap-5 justify-between">
                  <div className="flex items-center gap-3">
                    <img src={coin} alt="" />
                    <div>Katchit Coins</div>
                  </div>
                  <div className="text-[#FD5014]">{tokenAmount}</div>
                </div>
              </div>
            </div>
            <div
              className="shadow-field-outset mb-8 rounded-[30px] w-full min-w-[327px]"
              onClick={() => user_v1.navigate('/wallet')}
            >
              <div className="shadow-field-inset rounded-[30px] w-full min-w-[327px]">
                <div className="flex p-4 font-bold px-5 items-center gap-5 justify-between">
                  <div className="flex items-center gap-3">
                    <img src={wallet} alt="" />
                    <div>Wallet</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <div
        className="bottom-8 absolute w-full"
        onClick={() => user_v1.navigate('/map')}
      >
        <div
          className="action w-fit min-w-[213px] m-auto"
          onClick={() => navigate('/map')}
        >
          <Button title="Map" />
        </div>
      </div>
    </Wrapper>
  )
}

export default Index
