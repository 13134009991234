import React from 'react'
import { useNavigate } from 'react-router-dom'

const Index = ({ icon, to = '/' }) => {
  const navigate = useNavigate()
  return (
    <div className="shadow-field-outset rounded-2xl">
      <button
        onClick={() => navigate(to)}
        className="text-[#FF9B79] font-semibold text-[24px] rounded-2xl p-5 bg-white shadow-field-inset"
      >
        {icon}
      </button>
    </div>
  )
}

export default Index
