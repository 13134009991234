import { http } from '../config'

const PREFIX = ''

const getMe = async () => {
  try {
    let res = await http().get(
      `${PREFIX}/users/me?fields[]=first_name&fields[]=avatar.id&fields[]=role.admin_access&fields[]=role.app_access&fields[]=role.id&fields[]=role.enforce_tfa&fields[]=last_name&fields[]=id&fields[]=email`,
    )
    return res.data
  } catch (err) {
    throw err
  }
}
const getChallengeUser = async (user_id) => {
  try {
    let res = await http().get(
      `${PREFIX}/items/challange_users?filter[user_id][_eq]=${user_id}&fields[]=*&fields[]=challange_id.min_unlock&groupBy[]=user_id&meta=total_count&aggregate[count]=*&groupBy[]=challange_id`,
    )
    return res.data
  } catch (err) {
    throw err
  }
}
export { getMe, getChallengeUser }
