import useLoading from '../config/useLoading.api'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { getChallengeUser, getMe } from './user.api'
import { useEffect, useState } from 'react'
import { loadingIndicator } from '../../../utils/notify'

const useUsersApi = () => {
  const { loading, on, off } = useLoading()
  const navigate = useNavigate()
  const [user, setUser] = useState(null)
  const injectCookie = (data) => {
    Cookies.set('data', JSON.stringify(data))
    Cookies.set('user_id', data?.id)
    setUser(data)
  }
  const getMeDo = async () => {
    on()
    await getMe()
      .then((r) => {
        const data = r.data
        injectCookie(data)
      })
      .catch(() => {
        //clear all cookies
        Cookies.remove('data')
        Cookies.remove('access_token')
        Cookies.remove('refresh_token')
        setTimeout(() => {
          window.location.href = '/login'
        }, 1000)
      })
      .finally(() => off())
  }
  const getActive = async () => {
    if (!user && !Cookies.get('data')) {
      await getMe()
    }
    if (user) {
      return user
    }
    //get from cookies
    const data = Cookies.get('data')
    if (data) {
      setUser(JSON.parse(data))
      return JSON.parse(data)
    }
    return null
  }

  const getTokenAmount = (user_id) => {
    on()
    return getChallengeUser(user_id)
      .then((r) => {
        return r.meta
      })
      .finally(() => off())
  }

  useEffect(() => {
    if (loading) {
      loadingIndicator()
    }
  }, [loading])
  return {
    getMeDo,
    getTokenAmount,
    loading,
    navigate,
    user,
    getActive,
  }
}
export default useUsersApi
