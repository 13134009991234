import React, { useCallback, useEffect, useState } from 'react'
import Wrapper from '../../components/wrapper'
import TopTexture from '../../components/topTexture'
import BottomDrawer from '../../components/bottomDrawer'
import { motion } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { API_URL } from '../../service/api/config'
import useChallengesApi from '../../service/api/challenges/challenges.api'

const Index = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const challengesApi = useChallengesApi()
  const [recent, setRecent] = useState(null)
  const handleAction = () => {
    navigate('/map') // Ganti '/destination' dengan path tujuan Anda
  }
  const getMapImage = useCallback(() => {
    return `${API_URL}/assets/${location.state?.map_image}`
  }, [location.state])
  const getBrandImage = useCallback(() => {
    return `${API_URL}/assets/${location.state?.brand_image}`
  }, [location.state])

  useEffect(() => {
    challengesApi.getRecentCaptureDo().then((r) => {
      if (r) {
        setRecent(r)
      }
    })
  }, [])

  return (
    <Wrapper fullwidth position="start">
      <TopTexture />
      <div className="reward-icon" style={{ marginTop: '8rem' }}>
        <motion.img
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: 'spring',
            stiffness: 260,
            damping: 20,
          }}
          // style={{ width: ' calc(100dvw - 70%)' }}
          className="m-auto"
          src={getMapImage() ?? ''}
          alt=""
        />
      </div>
      <BottomDrawer
        icon={getBrandImage()}
        title={'Congratulation you have coin get more coins and scratch coupon'}
        description={`${recent?.total_captured ?? 0} of ${recent?.min_unlock ?? 0}`}
        actionText={'Map'}
        onAction={handleAction}
      />
    </Wrapper>
  )
}

export default Index
