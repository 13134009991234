import Wrapper from '../../components/wrapper'
import Logo from '../../../src/assets/svgs/logo.svg'
import Input from '../../components/input'
import Button from '../../components/button'
import useLoginApi from '../../service/api/login'
import { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import { notify } from '../../utils/notify'

const Index = () => {
  const loginApi = useLoginApi()
  //get token from qs
  const qs = window.location.search
  //token is exist
  const tokenExists = () => qs?.includes('?token=')
  const getToken = () => {
    return qs.split('?token=')[1]
  }
  const [password, setPassword] = useState('')
  //confirm
  const [confirmPassword, setConfirmPassword] = useState('')
  const handleReset = () => {
    //is token exist
    if (tokenExists()) {
      //check if password and confirm password is same
      if (password !== confirmPassword) {
        notify('Password and Confirm Password is not same')
        return
      }
      loginApi.resetPasswordDo({
        password: password,
        token: getToken(),
      })
      return
    }
    window.location.href = '/login'
  }

  useEffect(() => {
    setPassword('')
    setConfirmPassword('')
  }, [])

  return (
    <Wrapper position="start">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1000,
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
      >
        <div className="logo mt-12">
          <img
            src={Logo ?? ''}
            style={{ height: 190 }}
            alt="logo-katchit"
            className="mx-auto"
          />
        </div>
        <div className="form mt-12">
          <div className="mb-5 font-bold text-[36px] text-center text-white">
            Confirm Password
          </div>
          <div className="mb-4">
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <Input
              type="password"
              placeholder={'Confirm new password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <Button onClick={handleReset} title="Change Password" />
          </div>
        </div>
      </motion.div>
    </Wrapper>
  )
}

export default Index
