import { http } from '../config'
import { decryptMessage } from '../../../utils'

const PREFIX = ''
const getChallenges = async () => {
  try {
    let res = await http('json', process.env.REACT_APP_API_URL, false).get(
      `${PREFIX}/challenge/maps`,
    )
    return await decryptMessage(res.data)
  } catch (err) {
    throw err
  }
}
const getMapData = async (params) => {
  try {
    let res = await http('json', process.env.REACT_APP_API_URL, false).get(
      `${PREFIX}/challenge/maps`,
      {
        params,
      },
    )
    return await decryptMessage(res.data)
  } catch (err) {
    throw err
  }
}

const getWalletData = async () => {
  try {
    let res = await http('json', process.env.REACT_APP_API_URL, false).get(
      `${PREFIX}/me/wallet`,
    )
    return await decryptMessage(res.data)
  } catch (err) {
    throw err
  }
}
const getRecentCapture = async () => {
  try {
    let res = await http('json', process.env.REACT_APP_API_URL, false).get(
      `${PREFIX}/me/recent`,
    )
    return await decryptMessage(res.data)
  } catch (err) {
    throw err
  }
}

const captureChallengeV2 = async (body) => {
  try {
    let res = await http('json', process.env.REACT_APP_API_URL, false).post(
      `${PREFIX}/me/capture`,
      body,
    )
    return await decryptMessage(res.data)
  } catch (err) {
    throw err
  }
}
//me/claim

const claimChallenge = async (body) => {
  try {
    let res = await http('json', process.env.REACT_APP_API_URL, false).post(
      `${PREFIX}/me/claim`,
      body,
    )
    return await decryptMessage(res.data)
  } catch (err) {
    throw err
  }
}

const getChallenge = async (id) => {
  try {
    let res = await http().get(`${PREFIX}/items/challenges/${id}`)
    return await decryptMessage(res.data)
  } catch (err) {
    throw err
  }
}

const getCapturedChallenges = async (user_id) => {
  try {
    let res = await http('json', process.env.REACT_APP_API_URL, false).post(
      `${PREFIX}/me/capture`,
    )

    return await decryptMessage(res.data)
  } catch (err) {
    throw err
  }
}
export {
  getWalletData,
  getMapData,
  getChallenges,
  getChallenge,
  getCapturedChallenges,
  captureChallengeV2,
  getRecentCapture,
  claimChallenge,
}
