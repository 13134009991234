import Wrapper from '../../components/wrapper'
import Logo from '../../../src/assets/svgs/logo.svg'
import Input from '../../components/input'
import Button from '../../components/button'
import useLoginApi from '../../service/api/login'
import { useState } from 'react'
import { motion } from 'framer-motion'

const Index = () => {
  const [user, setUser] = useState('')
  const loginApi = useLoginApi()

  return (
    <Wrapper position="start">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1000,
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
      >
        <div className="logo mt-12">
          <img
            src={Logo ?? ''}
            style={{ height: 190 }}
            alt="logo-katchit"
            className="mx-auto"
          />
        </div>
        <div className="form mt-12">
          <div className="mb-5 font-bold text-[36px] text-center text-white">
            Forgot Password
          </div>
          <div className="mb-14">
            <Input
              type="email"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Button
              onClick={() => loginApi.requestResetDo(user)}
              title="Send Reset Link"
            />
          </div>
        </div>
      </motion.div>
    </Wrapper>
  )
}

export default Index
