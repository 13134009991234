import useLoading from '../config/useLoading.api'
import {
  captureChallengeV2,
  claimChallenge,
  getCapturedChallenges,
  getChallenge,
  getChallenges,
  getMapData,
  getRecentCapture,
  getWalletData,
} from './index'
import useUsersApi from '../user'
import { useEffect, useState } from 'react'

const useChallengesApi = (autoload = false) => {
  const { loading, on, off } = useLoading()
  const user_api = useUsersApi()
  const [triger, setTriger] = useState(false)
  const [captured, setCaptured] = useState([])
  const [walletItems, setWalletItems] = useState([])
  const onGetWallet = async () => {
    on()
    return getWalletData()
      .then((r) => setWalletItems(r.responseObject))
      .catch((e) => console.log(e))
      .finally(() => {
        off()
      })
  }
  const getRecentCaptureDo = async () => {
    on()
    return getRecentCapture()
      .then((r) => r?.responseObject)
      .catch((e) => console.log(e))
      .finally(() => off())
  }
  const onGetMapData = async (params) => {
    on()
    return getMapData(params)
      .then((r) => r.responseObject)
      .catch((e) => console.log(e))
      .finally(() => off())
  }
  const onGetChallenges = async () => {
    on()
    return getChallenges()
      .then((r) => r.responseObject)
      .catch((e) => console.log(e))
      .finally(() => off())
  }
  const getChallengeDo = async (id) => {
    on()
    return getChallenge(id)
      .then((r) => r.data)
      .catch((e) => console.log(e))
      .finally(() => off())
  }
  const captureDo = async (body) => {
    on()
    return await captureChallengeV2({
      challange_id: parseInt(body.challange_id, 10),
      swarm_id: String(body.swarm_id),
    })
      .then((r) => r.data)
      .finally(() => off())
  }
  const claimDo = async (body) => {
    on()
    return await claimChallenge({
      challange_id: parseInt(body.challange_id, 10),
    })
      .then((r) => r.data)
      .finally(() => {
        onGetWallet()
        off()
      })
  }

  const getCaptureDo = async (_id = null) => {
    on()
    const id = user_api.getActive()?.id
    return getCapturedChallenges(_id ?? id)
      .then((r) => {
        const unique = [
          ...new Map(r.data.map((item) => [item['swarm_id'], item])).values(),
        ]
        setCaptured(unique)
        return unique
      })
      .catch((e) => console.log(e))
      .finally(() => off())
  }

  const handleTriger = () => {
    setTriger(!triger)
  }

  useEffect(() => {
    if (autoload) onGetWallet()
  }, [triger, autoload])

  return {
    onGetChallenges,
    onGetMapData,
    loading,
    getChallengeDo,
    captureDo,
    getCaptureDo,
    captured,
    onGetWallet,
    getRecentCaptureDo,
    claimDo,
    walletItems,
    handleTriger,
  }
}

export default useChallengesApi
