import Swal from 'sweetalert2'

const notify = (description, showConfirm, confirmTitle, useTimer = true, allowClose = false) => {
  Swal.close()
  return Swal.fire({
    title: 'Notification',
    showConfirmButton: showConfirm,
    confirmButtonText: confirmTitle,
    customClass: {
      htmlContainer: '!text-[14px] !pb-4',
      popup: 'rounded-2xl max-w-[250px] py-5 px-5',
      title: 'text-[16px]',
    },
    allowOutsideClick: allowClose,
    showClass: {
      popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `,
    },
    hideClass: {
      popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `,
    },
    timer: useTimer ? 1500 : null,
    text: description,
  })
}

const loadingIndicator = () => {
  Swal.close()
  return Swal.fire({
    showConfirmButton: false,
    customClass: {
      htmlContainer: '!p-0',
      popup: 'rounded-2xl max-w-[95px] max-h-[100px]',
      title: 'text-[16px]',
    },
    didOpen: () => {
      Swal.showLoading()
    },
    allowOutsideClick: false,
    showClass: {
      popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `,
    },
    hideClass: {
      popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `,
    },
    timer: 1500,
  })
}

export { notify, loadingIndicator }
