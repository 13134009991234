import React from 'react'
import Envelope from '../../assets/svgs/envelope.svg'
import Password from '../../assets/svgs/password.svg'
import User from '../../assets/svgs/user.svg'

const Index = ({ type = 'email', value, onChange, placeholder }) => {
  const typeMap = {
    email: Envelope,
    password: Password,
  }
  const placeHolderMap = {
    email: 'Email...',
    password: 'Password...',
  }
  return (
    <div className="shadow-field-outset">
      <div className="flex gap-5 rounded-[18px] bg-white pe-[15px] py-[18px] ps-[18px] min-w-[325px] shadow-field-inset items-center">
        <div className="icon text-[18px]">
          <img src={typeMap[type] ?? User} alt="" />
        </div>
        <div className="w-full text-start">
          <input
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder ?? placeHolderMap[type]}
            className="outline-0 w-full text-gray-800"
          />
        </div>
      </div>
    </div>
  )
}

export default Index
