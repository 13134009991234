const collectComponents = (nav) => {
  return {
    schema: {
      link: { type: 'string' }, // default: ''
    },
    init() {
      const el = this.el

      // Menambahkan event listener untuk klik
      el.addEventListener('click', () => {
        const link = this.data.link
        if (link) {
          nav(link)
        }
      })
    },
  }
}

export { collectComponents }
