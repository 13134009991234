import {notify} from "../utils/notify";

const mapLoadingScreenComponent = {
    init() {
      const scene = this.el.sceneEl
      const gradient = document.getElementById('gradient')

  
      const dismissLoadScreen = () => {
        setTimeout(() => {
          gradient.classList.add('fade-out')
        }, 1500)
  
        setTimeout(() => {
          gradient.style.display = 'none'
        }, 2000)
      }

      const getPosition = function (options) {
        return new Promise((resolve, reject) => {
          if (!navigator.geolocation) {
            reject(new Error('Geolocation is not supported by your browser'));
          } else {
            navigator.geolocation.getCurrentPosition(resolve, reject, options);
          }
        });
      }

      getPosition()
          .then((position) => {
            if (scene.hasLoaded) {
              dismissLoadScreen();
            } else {
              scene.addEventListener('loaded', dismissLoadScreen);
            }
          })
          .catch((err) => {
            console.error(err.message);
            // Handle the error when geolocation is disabled or any other error
            handleGeolocationError(err);
          });

      function handleGeolocationError(err) {
        // Implement your error handling logic here
        notify("You should enable the permission please refresh and start again").then(r => window.open('/main'))

      }

    },
  }

  export { mapLoadingScreenComponent }