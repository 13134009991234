import Wrapper from '../../components/wrapper'
import Logo from '../../../src/assets/svgs/logo.svg'
import Input from '../../components/input'
import Button from '../../components/button'
import ButtonIcon from '../../components/buttonIcon'
import useLoginApi from '../../service/api/login'
import { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const Index = () => {
  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')
  const loginApi = useLoginApi()

  const handleLogin = (user, pass) => {
    loginApi.onLogin({
      email: user,
      password: pass,
    })
  }
  const toGoogle = () => {
    window.open(loginApi.googleRedirectLink(), '_self').focus()
  }

  return (
    <>
    <Wrapper position="start">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          duration: 1000,
          type: 'spring',
          stiffness: 260,
          damping: 20,
        }}
      >
        <div className="logo mt-12">
          <img
            src={Logo ?? ''}
            style={{ height: 190 }}
            alt="logo-katchit"
            className="mx-auto"
          />
        </div>
        <div className="form mt-12">
          <div className="mb-3">
            <Input
              type="email"
              value={user}
              onChange={(e) => setUser(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-3 font-bold text-[12px] text-[#FF9B79] text-center">
            <Link to="/reset">Forgot Password?</Link>
          </div>
          <div className="mb-3">
            <Button onClick={() => handleLogin(user, password)} title="Login" />
          </div>
          <div className="mb-3 text-white/70 text-center">OR</div>
          <div className="mb-3" onClick={toGoogle}>
            <ButtonIcon title={'Signin With Google'} />
          </div>
          <div className="font-bold text-[16px] text-white text-center">
            You have account? <Link to={'/signup'}> Sign Up</Link>
          </div>
        </div>
      </motion.div>
    </Wrapper>
    </>
  )
}

export default Index
