import React from 'react'
import Wrapper from '../../components/wrapper'
import NavButton from '../../components/navButton'
import 'swiper/css/navigation'
import {GrFormPrevious} from 'react-icons/gr'
import {HiLocationMarker} from 'react-icons/hi'
import TopTexture from '../../components/topTexture'
import Title from '../../components/title'
import RewardPopup from '../../components/rewardPopup'
import {motion} from 'framer-motion'
import useChallengesApi from '../../service/api/challenges/challenges.api'
import {API_URL} from '../../service/api/config'

const Index = () => {
    const challengesApi = useChallengesApi(true)
  const pathImage = (_path) => `${API_URL}/assets/${_path};`
  return (
    <Wrapper fullwidth position="start">
      <TopTexture />
      <div className="w-full">
        <Title title={'Wallet'} />
        <div className="nav-btn fixed left-5 top-7">
          <NavButton icon={<GrFormPrevious />} to={'/profile'} />
        </div>
      </div>
      <div className="w-full p-5">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{
            type: 'ease',
            stiffness: 260,
            damping: 20,
          }}
        >
          <RewardPopup
              data={challengesApi?.walletItems?.map((e) => ({
              ...e,
              is_locked: e?.captured_count < (e?.min_unlock ?? 999),
              description: 'Take this code to the Brand stand and pick up your prize!',
              map_image: pathImage(e.map_image),
              icon: pathImage(e.brand_image),
            }))}
              claimDo={event => challengesApi.claimDo(event).then(r => challengesApi.handleTriger())}
          />
        </motion.div>
      </div>
      <div className="nav-btn fixed bottom-7 right-5">
        <NavButton icon={<HiLocationMarker />} to={'/map'} />
      </div>
    </Wrapper>
  )
}

export default Index
