import Wrapper from '../../components/wrapper'
import Logo from '../../../src/assets/svgs/katchit-rockinrio.svg'
import Content from '../../../src/assets/svgs/content-main.svg'
import FootLogo from '../../../src/assets/svgs/tech-entreprise.svg'
import Button from '../../components/button'
import {motion} from 'framer-motion'
import useAccelerometer from "../../utils/useAccelerometer";
import {notify} from "../../utils/notify";

const Index = () => {
    const accelerometer = useAccelerometer()
    const motionSensor = () => {
        if (navigator.userAgent.toLowerCase()?.includes('android')) {
            requestPermission();
        } else {
            DeviceMotionEvent?.requestPermission()
                .then((response) => {
                    if (response === 'granted') {
                        requestPermission();
                    }
                })
                .catch(() => {
                    onDenied();
                });
        }
    };

    const requestPermission = async () => {
        try {
            // Request video permission
            const stream = await navigator.mediaDevices.getUserMedia({
                video: true,
            });

            // Check if geolocation is available
            if (!('geolocation' in navigator)) {
                // Geolocation is not available
                onDenied();
                // Stop the video stream
                stream.getTracks().forEach((track) => track.stop());
                return;
            }

            // If video permission granted, request geolocation permission
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Geolocation permission granted
                    onGranted();
                    // Stop the video stream
                    stream.getTracks().forEach((track) => track.stop());
                    // Optionally, use the geolocation data
                    console.log('Geolocation granted:', position.coords);
                },
                (error) => {
                    // Handle geolocation errors
                    if (error && (error.code === error.PERMISSION_DENIED || error.code === error.POSITION_UNAVAILABLE)) {
                        onDenied();
                    } else {
                        onDenied();
                    }
                    // Stop the video stream if geolocation permission is denied
                    stream.getTracks().forEach((track) => track.stop());
                }
            );
        } catch (error) {
            if (error && (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError')) {
                onDenied();
            } else {
                onDenied();
            }
        }
    };



    const onGranted = () => {
        window.open('/map', '_self')
    };

    const onDenied = () => {
        notify("You should enable the permission please refresh and start again").then(r => window.open('/main'))
    };

    const handleStart = () => {
        motionSensor()
    }

    return (
        <>
        
            <Wrapper position="center">
            <div className='flex flex-col items-center justify-center'>
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    transition={{
                        duration: 1000,
                        type: 'spring',
                        stiffness: 260,
                        damping: 20,
                    }}
                >
                    <div className="logo mt-5">
                        <img
                            src={Logo ?? ''}
                            style={{height: 150}}
                            alt="logo-katchit"
                            className="mx-auto"
                        />
                    </div>
                    <div className="form mt-12 flex flex-col gap-[10px]">
                        {/* <div className='bg-white min-w-[300px] rounded-2xl mb-10 w-full h-[300px]'>

                        </div> */}
                        <img src={Content} />
                        <img src={FootLogo} />

                        <div className="mb-3">
                            <Button onClick={() => handleStart()} title="Start Game"/>
                        </div>


                    </div>
                </motion.div>
                </div>
            </Wrapper>
            
        </>
    )
}

export default Index
