import React from 'react'
import bottomDrawer from '../../assets/svgs/bottomdrawer.svg'
import Button from '../button'
import {motion} from "framer-motion";

const Index = ({
  icon,
  title,
  description,
  actionText,
  onAction,
}) => {
  return (
      <motion.div
          initial={{position: "absolute", bottom: '-20rem'}}
          animate={{position: "absolute", bottom: 0}}
          transition={{
            type: "ease",
            stiffness: 260,
            damping: 20
          }}
          className="bottom-drawer fixed bottom-0 w-full pt-16">
      <img className=" w-full m-auto" src={bottomDrawer ?? ''} alt="" />
      <div className="m-auto w-full fixed mt-[-1rem]">
        <img
          className=" m-auto w-full"
          style={{ width: 70, height: 70 }}
          src={icon ?? ''}
          alt=""
        />
      </div>
      <div className="bg-[#f3fcff] pt-16 px-5 pb-16 mt-[-.25rem]">
        <div className="content">
          <div className="reward-title font-semibold text-[20px] text-center mb-4">
            {title}
          </div>
          <div className="reward-description font-semibold text-[17px] text-center mb-5">
            {description}
          </div>
          <div className="action w-fit min-w-[213px] m-auto">
            <Button onClick={onAction} title={actionText} />
          </div>
        </div>
      </div>
      </motion.div>
  )
}

export default Index
