import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Map } from './views/map'
import { Scene } from './views/scene'
import { NotFound } from './views/notfound'
import Login from './views/login'
import Splash from './components/splash'
import Reward from './views/reward'

import Wallet from './views/wallet'
import Profile from './views/profile'
import Signup from './views/signup'
import Reset from './views/reset'
import Confirmation from './views/confirmation'
import Main from './views/main'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/map" element={<Map />} />
        <Route exact path="/main" element={<Main />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/reset" element={<Reset />} />
        <Route exact path="/confirmation" element={<Confirmation />} />
        <Route exact path="/" element={<Splash />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/reward" element={<Reward />} />
        <Route exact path="/wallet" element={<Wallet />} />
        <Route exact path="/scene" element={<Scene />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
