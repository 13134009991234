import React, { useEffect } from 'react'
import { AFrameScene } from '../lib/aframe-components'
import html from './scene.html'
import { getTargetComponents } from '../lib/get-data'
import NavButton from '../components/navButton'
import { GrFormPrevious } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'
import useChallengesApi from '../service/api/challenges/challenges.api'
import useUsersApi from '../service/api/user'

const Scene = () => {
  const challenge_api = useChallengesApi()
  const user_api = useUsersApi()
  const navigate = useNavigate()
  return (
    <>
      <AFrameScene
        sceneHtml={html}
        components={[
          {
            name: 'get-target',
            val: getTargetComponents(navigate, challenge_api, user_api),
          },
        ]}
      />
      <div className="z-[99] absolute left-[2vw] top-[2vh]">
        <NavButton icon={<GrFormPrevious />} to={'/map'} />
      </div>
    </>
  )
}

export { Scene }
