import React, { useState } from 'react'
import rewardIconPlaceholder from '../../assets/pngs/headphone.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { TbCopyPlusFilled } from 'react-icons/tb'
import Scratcher from '../scratcher'
import { notify } from '../../utils/notify'
import { Player } from '@lottiefiles/react-lottie-player'
import Placeholder from '../../assets/lottie/loader.json'

const Index = ({ icon, data = [], claimDo = undefined }) => {
  console.log('njiir', data)
  const [qrValue, setQrValue] = useState('')
  return (
    <div className="shadow-field-outset rounded-[30px] mt-[-5rem]">
      <div className="rounded-[30px] bg-white px-8 pb-3 pt-4 text-center  shadow-field-inset">
        <div className="swiper m-auto mb-5">
          <Swiper
            navigation={true}
            modules={[Navigation]}
            spaceBetween={50}
            slidesPerView={1}
          >
            {!!data?.length ? (
              data?.map((item, key) => (
                <SwiperSlide key={key}>
                  <div>
                    <div className="reward-icon m-auto mb-5">
                      <img
                        src={item?.map_image ?? rewardIconPlaceholder}
                        className="m-auto"
                        style={{ maxHeight: 90 }}
                        alt=""
                      />
                    </div>
                    <div className="reward-title font-semibold text-[30px] mb-5">
                      {item?.reward_name}
                    </div>
                    <div className="reward-description font-semibold text-[17px] text-center mb-5">
                      {item.description}
                    </div>
                    <div className="mb-5">
                      {item?.is_claimed ? (
                        <>
                          {qrValue === item?.coupon_code && (
                            <div>
                              <img
                                style={{ height: 150, width: 150 }}
                                className="m-auto"
                                src={`${process.env.REACT_APP_API_URL}qr?code=${item?.coupon_code}`}
                                onError={() => {
                                  alert('Error Load QR')
                                }}
                                alt=""
                              />
                              <button
                                onClick={() => setQrValue('')}
                                style={{
                                  background: 'linear-gradient(180deg, #FF9B79 0%, #FD5014 100%)',
                                }} className="text-[12px] text-white py-1 px-4 rounded-full">Show Coin
                              </button>
                            </div>
                          )
                          }
                          {qrValue !== item?.coupon_code && (
                            <div>
                              <img
                                style={{ height: 105, width: 105 }}
                                className="m-auto mb-4"
                                src={item?.icon ?? icon}
                                alt=""

                              />
                              <button
                                onClick={() => setQrValue(item?.coupon_code)}
                                style={{
                                background: 'linear-gradient(180deg, #FF9B79 0%, #FD5014 100%)',
                              }} className='text-[12px] text-white py-1 px-4 rounded-full'>Show QR</button>
                            </div>
                          )}
                        </>
                      ) : (
                        <img
                          style={{ height: 105, width: 105 }}
                          className="m-auto"
                          src={item?.icon ?? icon}
                          alt=""
                        />
                      )}
                    </div>
                    <div className="description font-bold text-[21px] text-[#FF4040] mb-5">
                      {item?.name ?? '-'}
                    </div>
                    {item?.is_locked || item?.is_claimed ? (
                      <>
                        {!item?.is_claimed ? (
                          <div
                            className="label h-[40px] min-w-[135px] bg-[grey] items-center justify-center gap-2 flex bg-[#FF774833] w-fit m-auto px-3 py-2 rounded-full font-bold text-[14px] text-white mb-2">
                            Locked
                          </div>
                        ) : (
                          <div
                            className="label items-center justify-center gap-2 flex bg-[#FF774833] w-fit m-auto px-3 py-2 rounded-full font-semibold text-[14px] text-[#FF4040] mb-2">
                            <div>{item?.coupon_code}</div>
                            <div className="text-[24px]">
                              <TbCopyPlusFilled />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <Scratcher
                          hidenColor="grey"
                          style={{ borderRadius: '10rem' }}
                          width={135}
                          height={40}
                          finishPercent={45}
                          brushSize={10}
                          onComplete={() => {
                            claimDo({
                              challange_id: item?.id,
                            }).then(() => {
                              notify('Success to claim')
                            })
                          }}
                        >
                          <div
                            className="label items-center justify-center gap-2 flex bg-[#FF774833] w-fit m-auto px-3 py-2 rounded-full font-semibold text-[14px] text-[#FF4040] mb-2">
                            <div>{item?.coupon_code}</div>
                            <div className="text-[24px]">
                              <TbCopyPlusFilled />
                            </div>
                          </div>
                        </Scratcher>
                        <div className="text-[12px] mt-2">Scratch this hidden Voucher</div>
                      </>
                    )}
                  </div>
                </SwiperSlide>
              ))
            ) : (
              <div>
                <div className="reward-title font-semibold text-[30px] mt-5">
                  Oh No...
                </div>
                <Player
                  autoplay={true}
                  loop={true}
                  controls={false}
                  src={Placeholder}
                  style={{ height: '100%', width: '200px', marginLeft: 'auto' }}
                />
                <span>
                  your wallet is empty
                  <br />
                  go catch it the items
                </span>
              </div>
            )}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Index
