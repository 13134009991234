import React from 'react'
import Google from '../../assets/svgs/google.svg'

const Index = ({title}) => {
  return (
    <div>
      <button
        className="items-center justify-center shadow-field-inset flex gap-2 text-[14px] rounded-full pe-[15px] py-[18px] ps-[18px] font-semibold w-full"
        style={{
          background: 'linear-gradient(180deg, #F2FCFF 0%, #F8FCFF 100%)',
        }}
      >
        <>
          <img src={Google ?? ''} alt="" />
        </>
        <>{title}</>
      </button>
    </div>
  )
}

export default Index
